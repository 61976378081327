.form_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form_section {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form_section p {
  font-family: 'Ysabeau Infant 600';
  color: #f5f5f5;
}

.form_section_buttons {
  display: flex;
}

.plane_button {
  border-radius: 7px;
  text-align: center;
  text-transform: uppercase;
  background-color: #352947;
  color: #f5f5f5;
}

.plane_button.active {
  border: 1px solid #d7f84b;
}

.form_buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.social_buttons {
  display: flex;
  gap: 15px;
}

.social_button {
  background-color: transparent;
  border: none;
}

@media screen and (max-width: 800px) {
  .form_container {
    width: 100%;
  }

  .form_section p {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }

  .plane_button {
  font-family: 'Ysabeau Infant 600';
    padding: 8px 12px;
    font-size: 10px;
    font-weight: 600;
    line-height: 13.43px;
  }

  .form_send_button {
    width: 110px;
  }

  .social_button {
    width: 20px;
  }

  .form_section_buttons {
    gap: 6px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .form_container {
    width: 60%;
  }

  .form_section p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .plane_button {
  font-family: 'Ysabeau Infant 500';
    padding: 10px 20px 10px 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.2px;
  }

  .form_send_button {
    width: 124px;
  }

  .social_button {
    width: 25px;
  }

  .form_section_buttons {
    gap: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .form_container {
    width: 60%;
  }

  .form_section p {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .plane_button {
  font-family: 'Ysabeau Infant 500';
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.8px;
  }
  
  .form_send_button {
    width: 180px;
  }

  .social_button {
    width: 30px;
  }

  .form_section_buttons {
    gap: 16px;
  }
}