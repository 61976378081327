.pricePageContent_mainDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  background-color: #251d32;
}

.pricePageContent_mainDescription_text {
  height: 100%;
  font-family: 'Ysabeau Infant 400';
}

.pricePageContent_innerContainer {
  display: flex;
  gap: 20px;
  width: 100%;
  height: fit-content;
}

@media screen and (max-width: 800px) {
  .pricePageContent_mainDescription {
    margin-top: 30px;
    padding: 0px 8px;
  }

  .pricePageContent_mainDescription img {
    width: 140px;
  }

  .pricePageContent_mainDescription_text {
    width: 250px;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.49px;
  }

  .pricePageContent_innerContainer {
    margin-top: 20px;
    flex-direction: column;
    background-image: url('../../shared/images/linePhone.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .pricePageContent_innerContainer > div:nth-child(1),
  .pricePageContent_innerContainer > div:nth-child(3) {
    align-self: flex-start;
  }

  .pricePageContent_innerContainer > div:nth-child(2),
  .pricePageContent_innerContainer > div:nth-child(4) {
    align-self: flex-end;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .pricePageContent_mainDescription {
    margin-top: 50px;
    padding: 0px 32px 0px 32px;
  }

  .pricePageContent_mainDescription_text {
    width: 547px;
    padding: 32px 0px 32px 0px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.86px;
  }

  .pricePageContent_innerContainer {
    margin-top: 50px;
    flex-direction: column;
    background-image: url('../../shared/images/linePhone.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .pricePageContent_innerContainer > div:nth-child(1),
  .pricePageContent_innerContainer > div:nth-child(3) {
    align-self: flex-start;
  }

  .pricePageContent_innerContainer > div:nth-child(2),
  .pricePageContent_innerContainer > div:nth-child(4) {
    align-self: flex-end;
  }
}

@media screen and (min-width: 1280px) {
  .pricePageContent_mainDescription {
    margin-top: 80px;
    padding: 0px 40px 0px 40px;
  }

  .pricePageContent_mainDescription_text {
    width: 547px;
    padding: 40px 0px 40px 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 26.86px;
  }

  .pricePageContent_innerContainer {
    margin-top: 50px;
    flex-direction: row;
    background-image: none;
  }

  .pricePageContent_innerContainer_left,
  .pricePageContent_innerContainer_right {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  .pricePageContent_innerContainer_middle {
    padding-top: 50px;
  }

  .pricePageContent_innerContainer_right {
    padding-top: 145px;
  }
}
