.price_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.priceCard_wrapper {
  display: flex;
  justify-content: space-between; /* Равномерное расположение карточек */
  align-items: flex-start; /* Сохраняем выравнивание карточек сверху */
  gap: 20px; /* Отступы между карточками */
}

.price_moreInfo_button {
  display: flex;
  align-items: end;
  flex-direction: column;
}

.price_innerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .price_moreInfo_button {
    margin-top: 20px;
  }

  .price_innerContainer {
    margin-top: 15px;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .price_moreInfo_button {
    margin-top: 10px;
  }

  .price_innerContainer {
    margin-top: 15px;
    flex-direction: row;
    gap: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .price_moreInfo_button {
    margin-top: 40px;
  }

  .price_innerContainer {
    margin-top: 30px;
  }
}
