.cookies-container {
    position: fixed;
    bottom: 20px;
    right: 20px; /* Сдвигаем вправо */
    background-color: #251D32; /* Тёмный фон в стилистике сайта */
    color: #eaeaea; /* Светлый текст */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px; /* Компактный размер */
    padding: 16px;
    font-family: "Ysabeau Infant 400"; /* Шрифт сайта */
  }
  
  .cookies-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравниваем текст и кнопку по левому краю */
    text-align: left;
  }
  
  .cookies-content p {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 1.5; /* Удобочитаемость текста */
  }
  
  .cookies-button {
    background-color: #d4ff00; /* Жёлтая кнопка в стилистике сайта */
    color: #1a1a2e; /* Тёмный текст на кнопке */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .cookies-button:hover {
    background-color: #4D4F1C; /* Темнее при наведении */
  }

  .docsLink {
    text-decoration: underline;
    color: #d4ff00;
  }