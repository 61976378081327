.horizontal-scroll {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .scroll-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: white;
    position: absolute;
    z-index: 10;
  }
  
  .scroll-button.left {
    left: 0;
  }
  
  .scroll-button.right {
    right: 0;
  }
  
  .scroll-container {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    scrollbar-width: none; /* для скрытия полосы прокрутки в Firefox */
    scroll-behavior: smooth;
    padding: 16px 0;
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none; /* для скрытия полосы прокрутки в Chrome */
  }
  
  .scroll-item {
    min-width: 200px;
    height: 150px;
    flex-shrink: 0;
    background-color: #251d32;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .scroll-item img {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }
  