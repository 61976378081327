.notesListItem_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  background-color: #251d32;
  border-radius: 20px;
}

.notesListItem_inner_container {
  display: flex;
  gap: 30px;
}

.notesListItem_img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notesListItem_info_container {
  position: relative;
  width: 100%;
  padding-right: 10px;
  text-align: justify;
}

.notesListItem_info_container button {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 800px) {
  .notesListItem_container h2 {
    font-family: 'Ysabeau Infant 600';
    font-size: 18px;
  }

  .notesListItem_info_container {
    font-family: 'Ysabeau Infant 400';
    font-size: 14px;
    padding-bottom: 40px;
  }

  .notesListItem_img_container {
     display: none;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .notesListItem_container h2 {
    font-family: 'Ysabeau Infant 600';
    font-size: 22px;
  }

  .notesListItem_info_container {
    font-family: 'Ysabeau Infant 400';
    font-size: 16px;
  }

  .notesListItem_img_container {
    width: 30%;
    overflow: hidden;
  }
}

@media screen and (min-width: 1280px) {
  .notesListItem_container h2 {
    font-family: 'Ysabeau Infant 600';
    font-size: 22px;
  }

  .notesListItem_info_container {
    font-family: 'Ysabeau Infant 400';
    font-size: 20px;
  }


  .notesListItem_img_container {
    width: 20%;
    overflow: hidden;
  }
}