/* Общий фон и выравнивание */
.page-404 {
    background-color: #121212;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  /* Полоски */
  .error-image {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    display: block;
  }
  
  /* Текст под полосками */
  .error-text {
    font-size: 1.5rem;
    margin: 20px 0;
    color: #aaa;
  }
  
  /* Кнопка возврата */
  .go-back-button {
    background-color: #00ff00;
    border: none;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .go-back-button:hover {
    background-color: #00cc00;
  }
  