.notesList_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
  .notesList_container {
    padding-top: 64px;
    gap: 15px;
  }

}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .notesList_container {
    padding-top: 80px;
    gap: 30px;
  }

}

@media screen and (min-width: 1280px) {
  .notesList_container {
    padding-top: 83px;
    gap: 35px;

  }
}