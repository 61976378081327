.info_container {
  display: flex;
  height: 100%;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
}

.info_email_section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info_email_section p:nth-child(1),
.info_ofice_section>p:nth-child(1) {
  text-transform: uppercase;
  color: #999999;
}

.info_email_section a,
.info_ofice_section>p:nth-child(2),
.info_ofice_section_list p {
  font-family: 'Ysabeau Infant 600';
}

.info_email_section a,
.info_ofice_section>p:nth-child(2) {
  color: #f5f5f5;
}

.info_ofice_section {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.info_ofice_section_list {
  display: flex;
}

.info_ofice_section_list p {
  color: #b8b8b8;
}

.info_copyRight {
  color: #f5f5f5;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .info_container {
    margin-bottom: 25px;
  }

  .info_email_section {
    margin-bottom: 0px;
  }

  .info_email_section p:nth-child(1),
  .info_ofice_section>p:nth-child(1) {
    font-family: 'Ysabeau Infant 300';
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }

  .info_email_section p {
    text-align: left;
  }

  .info_email_section a,
  .info_ofice_section>p:nth-child(2),
  .info_ofice_section_list p {
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
  }

  .info_copyRight {
    font-family: 'Ysabeau Infant 400';
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .info_ofice_section_list {
    gap: 0px;
    flex-direction: column;
  }

  .info_ofice_section {
    gap: 3px;
  }
}

@media screen and (min-width: 800px) {
  .info_email_section {
    text-align: left;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .info_email_section {
    margin-bottom: 30px;
  }

  .info_email_section p:nth-child(1),
  .info_ofice_section>p:nth-child(1) {
    font-family: 'Ysabeau Infant 300';
    font-size: 18px;
    font-weight: 300;
    line-height: 24.17px;
  }
 
  .info_email_section a,
  .info_ofice_section>p:nth-child(2),
  .info_ofice_section_list p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.17px;
  }

  .info_ofice_section_list {
    gap: 8px;
    flex-direction: column;
    align-items: end;
  }

  .info_copyRight {
    font-family: 'Ysabeau Infant 400';
    font-size: 14px;
    font-weight: 400;
    line-height: 18.8px;
    left: 0;
  }

  .info_ofice_section {
    gap: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .info_email_section {
    margin-bottom: 60px;
  }

  .info_email_section p:nth-child(1),
  .info_ofice_section>p:nth-child(1) {
    font-family: 'Ysabeau Infant 500';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .info_email_section a,
  .info_ofice_section>p:nth-child(2),
  .info_ofice_section_list p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .info_ofice_section_list {
    gap: 30px;
    flex-direction: row;
  }

  .info_copyRight {
    font-family: 'Ysabeau Infant 600';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .info_ofice_section {
    gap: 10px;
  }
}