.footer_container {
  width: 100%;
  height: fit-content;
  background-color: #251d32;
}

.footer_innerContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  margin-top: 15px;
}

@media screen and (max-width: 800px) {
  .footer_container {
    padding: 30px 15px;
    border-radius: 64px 64px 0px 0px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .footer_container {
    border-radius: 80px 80px 0px 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .footer_container {
    border-radius: 80px 80px 0px 0px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}