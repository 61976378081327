.price_header_container {
  display: flex;
}

.price_header_buttons_container {
  display: flex;
  gap: 16px;
}

.price_navButton {
  width: fit-content;
  background-color: #251d32;
  font-family: 'Ysabeau Infant 600';
  letter-spacing: 0.04em;
  color: #f5f5f5;
  text-transform: uppercase;
  text-align: center;
}

.price_navButton.active {
  border: 1px solid #d7f84b;
}

@media screen and (max-width: 800px) {
  .price_container {
    padding-top: 64px;
  }

  .price_header_container {
    flex-direction: column;
    gap: 20px;
  }

  .price_navButton {
    height: 32px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.12px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .price_container {
    padding-top: 80px;
  }

  .price_header_container {
    align-items: center;
    justify-content: space-between;
  }

  .price_navButton {
    height: 51px;
    padding: 10px 24px 16px 24px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 28.49px;
  }
}

@media screen and (min-width: 1280px) {
  .price_container {
    padding-top: 83px;
  }

  .price_header_container {
    align-items: center;
    justify-content: space-between;
  }

  .price_navButton {
    height: 53px;
    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.49px;
  }
}
