/* Общие стили для заголовков и текста */
.comparison h1 {
  text-align: center;
  margin-bottom: 30px;
}

.comparison h2 {
  font-family: 'Ysabeau Infant 600';
  color: #d4ff00;
  margin-bottom: 20px;
  border-left: 4px solid #d4ff00;
  padding-left: 15px;
}

.comparison h3 {
  font-family: 'Ysabeau Infant 600';
  margin-top: 20px;
  margin-bottom: 10px;
  color: #fff;
  line-height: 1.5;
}

.comparison h3::before {
  margin-right: 16px;
  font-weight: bold;
  color: #d4ff00;
}

.comparison p {
  color: #bbb;
  line-height: 1.8;
  margin-bottom: 15px;
  margin-right: 15px;
  font-family: 'Ysabeau Infant 400';
  text-align: justify;

}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.comparison {
  margin-top: 80px;
  margin-bottom: 50px;
}

.comparison-img {
  height: fit-content;
  width: 100%;
}

.comparisonNote_button {
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .comparison h1 {
    font-size: 22px;
  }

  .comparison h2 {
    font-size: 18px;
  }

  .comparison h3 {
    font-size: 18px;
  }
  .comparison p {
    font-size: 14px;
    margin-left: 15px;
  }

  .comparisonBut {
    font-size: 14px;
    margin-left: 15px;
    text-align: justify;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .comparison h1 {
    font-size: 30px;
  }

  .comparison h2 {
    font-size: 24px;
  }

  .comparison h3 {
    font-size: 22px;
  }
  .comparison p {
    font-size: 20px;
    margin-left: 15px;
  }

  .comparisonBut {
    font-size: 20px;
    margin-left: 15px;
    text-align: justify;
  }
}

@media screen and (min-width: 1280px) {
  .comparison h1 {
    font-size: 40px;
  }

  .comparison h2 {
    font-size: 24px;
  }

  .comparison h3 {
    font-size: 22px;
  }
  .comparison p {
    font-size: 20px;
    margin-left: 15px;
  }

  .comparisonBut {
    font-size: 20px;
    margin-left: 15px;
    text-align: justify;
  }
}