.expandedDiv_container {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  background-color: #251d32;
  cursor: pointer
}

.expandedDiv_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expandedDiv_title_text, .expandedDiv_title_text_bigger {
  display: flex;
  gap: 5px;
}

.expandedDiv_title_text p:nth-child(1) {
  color: #f5f5f5;
}

.expandedDiv_title_text p:nth-child(2) {
  color: #b8b8b8;
}

.arrowDown {
  rotate: 180deg;
}

.expandedDiv_text {
  margin-top: 10px;
}

.button-text-green{
  padding: 0;
  border: none;
  color: #d4ff00;
  background: transparent;
  padding: 0;
  font-family: 'Ysabeau Infant 400';
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
@media screen and (max-width: 800px) {
  .expandedDiv_container {
    padding: 10px;
  }

  .expandedDiv_title_text,
  .expandedDiv_text {
    font-family: 'Ysabeau Infant 400';
    font-size: 16px;
    font-weight: 400;
    line-height: 21.49px;
  }

  .expandedDiv_title_text_bigger {
    font-family: 'Ysabeau Infant 600';
    font-size: 18px;
    font-weight: 600;
  }

  .expandedDiv_title img {
    width: 11px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .expandedDiv_container {
    padding: 15px;
  }

  .expandedDiv_title_text,
  .expandedDiv_text {
    font-family: 'Ysabeau Infant 400';
    font-size: 15px;
    font-weight: 400;
    line-height: 21.49px;
  }

  .expandedDiv_title_text_bigger {
    font-family: 'Ysabeau Infant 600';
    font-size: 18px;
    font-weight: 600;
  }

  .expandedDiv_title img {
    width: 11px;
  }
}

@media screen and (min-width: 1280px) {
  .expandedDiv_container {
    padding: 30px;
  }

  .expandedDiv_title_text {
    font-family: 'Ysabeau Infant 500';
    font-size: 20px;
    font-weight: 500;
    line-height: 26.86px;
  }

  .expandedDiv_title_text_bigger {
    font-family: 'Ysabeau Infant 700';
    font-size: 20px;
    font-weight: 700;
  }

  .expandedDiv_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 26.86px;
  }
}
