.stepCard_container {
  border-radius: 10px;
  background-color: #251d32;
}

.stepCard_container_title {
  display: flex;
  justify-content: space-between;
  font-family: 'Ysabeau Infan 500';
  text-align: left;
  color: #ffffff;
}

.stepCard_container_text {
  margin-top: 16px;
  width: 100%;
  font-family: 'Ysabeau Infant 300';
  text-align: left;
}

@media screen and (max-width: 800px) {
  .stepCard_container {
    width: 80%;
    height: fit-content;
    padding: 12px 16px 12px 16px;
  }

  .stepCard_container_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.49px;
  }

  .stepCard_container_text {
    font-size: 13px;
    font-weight: 300;
    line-height: 18.8px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .stepCard_container {
    width: 50%;
    height: fit-content;
    padding: 12px 16px 12px 16px;
  }

  .stepCard_container_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.49px;
  }

  .stepCard_container_text {
    font-size: 13px;
    font-weight: 300;
    line-height: 18.8px;
  }
}

@media screen and (min-width: 1280px) {
  .stepCard_container {
    width: 464px;
    height: 275px;
    min-height: 240px;
    padding: 32px 40px 32px 40px;
  }

  .stepCard_container_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26.86px;
  }

  .stepCard_container_text {
    font-size: 16px;
    font-weight: 300;
    line-height: 21.49px;
  }
}
