.services_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.services_innerContainer {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.services_nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.services_navButton {
  background-color: #251d32;
  font-family: 'Ysabeau Infant 600';
  color: #f5f5f5;
  text-transform: uppercase;
  text-align: left;
}

.services_navButton.active {
  border-bottom: 1px solid #d7f84b;
  border-top: 1px solid #d7f84b;
  border-right: 1px solid #d7f84b;
}

.services_infoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text_container {
  width: 100%;
  height: fit-content;
  background-color: #251d32;
  font-family: 'Ysabeau Infant 400';
}
.text-green {
  color: #d4ff00;
}

.text-grey {
  color: #b8b8b8;
}

@media screen and (max-width: 800px) {

  .services_nav img {
    display: none;
  }

  .services_innerContainer {
    flex-direction: column;
    gap: 20px;
  }

  .services_navButton {
    width: 229px;
    padding: 16px 12px 16px 12px;
    border-radius: 0px 10px 10px 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.12px;
    letter-spacing: 0.04em;
  }

  .services_infoContainer {
    width: 100%;
  }

  .text_container {
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.49px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .services_innerContainer {
    flex-direction: row;
  }

  .services_navButton {
    width: 257px;
    padding: 20px 12px 20px 13px;
    border-radius: 0px 10px 10px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18.8px;
  }

  .services_infoContainer {
    width: 60%;
  }

  .text_container {
    padding: 15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24.17px;
  }
}

@media screen and (min-width: 1280px) {
  .services_innerContainer {
    flex-direction: row;
  }

  .services_navButton {
    width: 350px;
    padding: 30px 3px 30px 15px;
    border-radius: 0px 20px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    line-height: 26.86px;
  }

  .services_infoContainer {
    width: 60%;
  }

  .text_container {
    font-size: 18px;
    padding: 30px;
    border-radius: 10px;
    line-height: 26.86px;
  }
}
