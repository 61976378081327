.priceCard_container {
    border-radius: 15px;
    background-color: #251D32;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
}

.priceCard_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.priceCard_title_text {
    display: flex;
    flex-direction: column;
    font-family: 'Ysabeau Infant 500';
    display: flex;
    gap: 5px;
}

.priceCard_text {
    margin-top: 20px;
    font-family: 'Ysabeau Infant 500';
}

@media screen and (max-width: 800px) {
    .priceCard_container {
        width: 100%;
        padding: 24px;
        margin-top: 20px;
    }

    .priceCard_title img{
        width: 30px;
    }

    .priceCard_title_text,
    .priceCard_text {
        font-size: 16px;
        font-weight: 500;
        line-height: 21.49px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
    .priceCard_container {
        width: 48%;
        padding: 24px;
    }

    .priceCard_title img{
        width: 30px;
    }

    .priceCard_title_text,
    .priceCard_text {
        font-size: 18px;
        font-weight: 500;
        line-height: 24.49px;
    }
}

@media screen and (min-width: 1280px) {
    .priceCard_container {
        width: 48%;
        padding: 30px;
    }

    .priceCard_title_text,
    .priceCard_text {
        font-size: 20px;
        font-weight: 500;
        line-height: 26.86px;
    }
}