.header_container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #170f22;
}

.header_innerContsiner {
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo a {
  display: flex;
  align-items: center;
  font-family: 'Ysabeau Infant 700';
  text-decoration: none;
}

.logo-symbol {
  color: #d4ff00;
}

.logo-text {
  color: #ffffff;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav {
  display: flex;
}

.nav button,
.nav span {
  color: #b8b8b8;
  background: none;
  /* Убирает фон кнопки */
  border: none;
  /* Убирает рамку кнопки */
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  /* Делает указатель курсора как у ссылки */
  transition: color 0.3s ease-in-out;
}

.nav button:hover,
.nav span:hover {
  color: #d4ff00;
}

.header-right {
  display: flex;
  align-items: center;
}

/* Блок выбора языка */
.language-switcher {
  display: flex;
  align-items: center;
  background-color: #0c0416;
  border: 1px solid #d7f84b;
}

.language-button {
  background: none;
  border: none;
  font-family: 'Ysabeau Infant 600';
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-button.active {
  color: #d7f84b;
}

.language-divider {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.menuBut {
  color: #fffafa;
  font-family: 'Ysabeau Infant 600';
  font-size: 11px;
  font-weight: 600;
  line-height: 18.8px;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
}

.phoneNav_container {
  position: fixed;
  top: 68px;
  right: 3%;
  width: 180px;
  background-color: #251d32;
  padding: 10px 13px;
  border-radius: 8px;
  border: 1px solid #d7f84b;
}

.phoneNav_container.closed {
  display: none;
}

.phoneNav_container.open {
  display: block;
}

@media screen and (max-width: 800px) {
  .header_container {
    height: 64px;
    max-width: 100%;
  }

  .header_innerContsiner {
    width: 95%;
  }
  .logo a {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.49px;
  }

  .nav {
    gap: 16px;
    flex-direction: column;
    align-items: end;
  }

  .nav button,
  .nav span {
    font-family: 'Ysabeau Infant 700';
    font-size: 14px;
    font-weight: 700;
    line-height: 18.8px;
    letter-spacing: 0.04em;
  }

  .header-right {
    gap: 8px;
    margin-right: 0;
  }

  .language-switcher {
    gap: 10px;
    border-radius: 6px;
    padding: 4px 16px;
    height: 27px;
  }

  .language-button {
    font-size: 11px;
    font-weight: 600;
    line-height: 18.8px;
    letter-spacing: 0.04em;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .header_innerContsiner {
    width: 85%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .header_container {
    height: 80px;
  }

  .logo a {
    font-size: 18px;
    font-weight: 700;
    line-height: 24.17px;
  }

  .nav {
    gap: 16px;
  }

  .nav button,
  .nav span {
    font-family: 'Ysabeau Infant 400';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.12px;
  }

  .header-right {
    gap: 8px;
    margin-right: 0px;
  }

  .language-switcher {
    gap: 7px;
    border-radius: 6px;
    padding: 4px 6px;
    height: 32px;
  }

  .language-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .header_container {
    height: 83px;
  }

  .logo a {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
  }

  .nav {
    gap: 30px;
  }

  .nav button,
  .nav span {
    font-family: 'Ysabeau Infant 600';
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }

  .header-right {
    gap: 20px;
    margin-right: 20px;
  }

  .language-switcher {
    gap: 10px;
    border-radius: 9px;
    padding: 9px 15px;
    height: 42px;
  }

  .language-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .header_innerContsiner {
    width: 1140px;
  }
}
