.popup_container {
  display: none;
}

.popup_container.open {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 2;
}

.popup_innerContainer {
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  background-color: #251d32;
  height: fit-content;
  border: 0.5px solid #d7f84b;
  border-radius: 10px;
}

.popup_innerContainer p {
  color: #f5f5f5;
  text-align: center;
}

.popup_innerContainer p:nth-child(1) {
  font-family: 'Ysabeau Infant 700';
  font-size: 32px;
  line-height: 42.98px;
}

.popup_innerContainer p:nth-child(2) {
  font-family: 'Ysabeau Infant 300';
  font-size: 20px;
  line-height: 26.86px;
}

@media screen and (max-width: 800px) {
  .popup_innerContainer {
    padding: 40px;
    gap: 40px;
    width: 85%;
  }
}

@media screen and (min-width: 800px) {
  .popup_innerContainer {
    padding: 40px;
    gap: 40px;
    width: 490px;
  }
}
