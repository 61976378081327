.mainBanner_container {
  height: 100vh;
  width: 100%;
}

.mainBanner_textContainer {
  margin: 0 auto;
  font-family: 'Vetrino', sans-serif;
  color: #f5f5f5;
  position: relative;
}

.mainBanner_textContainer p:nth-child(1) {
  width: 100%;
  text-align: left;
}

.mainBanner_textContainer p:nth-child(2) {
  width: 100%;
  text-align: right;
}

.mainBanner_textContainer img {
  position: absolute;
  rotate: -3.97deg;
}

.mainBanner_img {
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 800px) {
  .mainBanner_container {
    overflow: hidden;
    position: relative;
  }

  .mainBanner_textContainer {
    padding-top: 250px;
    width: 82%;
    font-size: 36px;
    font-weight: 400;
    line-height: 38.74px;
    letter-spacing: 0.04em;
  }

  .mainBanner_textContainer img {
    width: 165.93px;
    bottom: -4px;
    right: 0;
  }

  .mainBanner_img {
    display: block;
    height: 40%;
    object-fit: cover;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .mainBanner_container {
    overflow: hidden;
    position: relative;
  }

  .mainBanner_textContainer {
    padding-top: 250px;
    width: 70%;
    font-size: 64px;
    font-weight: 400;
    line-height: 68.86px;
  }

  .mainBanner_textContainer img {
    width: 300px;
    bottom: -13px;
    right: -5px;
  }
  
  .mainBanner_img {
    display: block;
    height: 40%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1280px) {
  .mainBanner_container {
    overflow: hidden;
    position: relative;
  }
  
  .mainBanner_textContainer {
    padding-top: 2.5em;
    width: 881px;
    font-size: 80px;
    font-weight: 400;
    line-height: 92px;
    letter-spacing: 0.04em;
  }

  .mainBanner_textContainer img {
    bottom: -22px;
    right: -79px;
  }

  .mainBanner_img {
    display: block;
    height: 60%;
    object-fit: cover;
  }
}
