@font-face {
  font-family: 'Vetrino';
  font-style: normal;
  font-weight: 400;
  src:
    url('./shared/fonts/Vetrino.otf') format('opentype');
}

@font-face {
  font-family: 'Ysabeau Infant 300';
  font-style: normal;
  font-weight: 300;
  src:
    url('./shared/fonts/static/YsabeauInfant-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Ysabeau Infant 400';
  font-style: normal;
  font-weight: 400;
  src:
    url('./shared/fonts/static/YsabeauInfant-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Ysabeau Infant 500';
  font-style: normal;
  font-weight: 500;
  src:
    url('./shared/fonts/static/YsabeauInfant-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Ysabeau Infant 600';
  font-style: normal;
  font-weight: 600;
  src:
    url('./shared/fonts/static/YsabeauInfant-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Ysabeau Infant 700';
  font-style: normal;
  font-weight: 700;
  src:
    url('./shared/fonts/static/YsabeauInfant-Bold.ttf') format('truetype');
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

input {
  width: 100%;
  background-color: transparent;
  color: #f5f5f5;
  font-family: 'Ysabeau Infant 300';
  text-align: left;
  border-right: none;
}

input:focus {
  outline: none;
}

.App {
  width: 100%;
  background-color: #170f22;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  color: #f5f5f5;
  position: relative;
  min-height: 100vh;
}

.mainContainer {
  margin: 0 auto;
}

h1 {
  font-family: 'Vetrino', sans-serif;
  text-align: left;
  color: #f5f5f5;
}

.containerSubTitle {
  font-family: 'Ysabeau Infant 300';
  letter-spacing: 0.04em;
  color: #b8b8b8;
  text-transform: uppercase;
}

.colorfull-button {
  width: auto;
  border: none;
  text-transform: uppercase;
  /* Увеличили для центровки текста */
  color: #000;
  background: linear-gradient(90deg, #daff3d 0%, #c58efe 100%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.colorfull-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(197, 142, 254, 0.5);
}

.colorfull-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 800px) {
  input {
    border-bottom: 2px solid #3d3d3d;
    padding: 3px 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }

  .mainContainer {
    width: 95%;
  }

  h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 34.43px;
  }

  .containerSubTitle {
    font-size: 12px;
    font-weight: 300;
    line-height: 21.49px;
  }

  .colorfull-button {
    font-family: 'Ysabeau Infant 700';
    height: 29px;
    padding: 8px 24px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 700;
    line-height: 13.43px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  input {
    border-bottom: 2px solid #3d3d3d;
    padding: 3px 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
  }

  .mainContainer {
    width: 85%;
  }

  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 51.65px;
  }

  .colorfull-button {
  font-family: 'Ysabeau Infant 600';
    height: 32px;
    padding: 9px 15px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (min-width: 1140px) and (max-width: 1400px) {
  .mainContainer {
    width: 85%;
  }
}

@media screen and (min-width: 1140px) {
  input {
    border-bottom: 2px solid #3d3d3d;
    padding: 3px 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  h1 {
    font-size: 70px;
    font-weight: 400;
    line-height: 75.32px;
  }

  .colorfull-button {
  font-family: 'Ysabeau Infant 600';
    height: 42px;
    padding: 9px 15px;
    border-radius: 9px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1400px) {
  .mainContainer {
    width: 1140px;
  }
}
